#linkTitle {
  margin-bottom: 0px;
  font-size: 25px;
  text-shadow: 1px 1px #485543;
  text-align: center;
  margin-top: 30px;
}
#links {
  color: #6e6f58;
  font-family: "Poiret One", cursive;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.LinkHREFS {
  text-decoration: none;
  color: #f3efe2;
  padding: 10px;
  font-size: 18px;
  text-shadow: 2px 2px #b8837e;
}
.linktext {
  color: #bf8844;
  text-shadow: 1px 1px #bf8844;
  font-size: 25px;
  font-weight: bolder;
  margin-top: 12px;
}
.linkrefdiv {
  display: flex;
  flex-direction: row;
}
.linkDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (min-width: 800px) {
  #linkTitle {
    font-size: 30px;
  }
  .LinkHREFS {
    font-size: 30px;
  }
  .linktext {
    font-size: 30px;
    margin-top: 12px;
  }
}
