.footer {
  background-color: #c48370;
  border-top: 1.5px solid #a87060;
  font-size: 10px;
  max-width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  justify-content: space-evenly;
  bottom: 0;
  right: 0;
  left: 0;
  box-shadow: 0 0 8px #957366;
}
.footerText {
  color: #f7ecdb;
  text-decoration: none;
}

@media only screen and (min-width: 800px) {
  .footer {
    display: flex;
    align-items: center;
    position: fixed;
  }
  .footerText {
    color: #f7ecdb;
    text-decoration: none;
    font-size: 20px;
  }
}
