#home {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.selfie {
  width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  border-radius: 46%;
  border: 1px solid #d49643;
  box-shadow: 0 0 8px #a16e4f;
}
.lineBelowName {
  width: 300px;
  height: 1.5px;
  background-color: #a56b61;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.MyNameIs {
  color: #f7ecdb;
  font-size: 25px;
  text-shadow: 2px 2px #b8837e;
  font-family: "Cormorant", serif;
  text-align: center;
  margin-bottom: 20px;
}
.sam {
  color: #f7ecdb;
  font-size: 25px;
  text-shadow: 2px 2px #b8837e;
  font-family: "Cormorant", serif;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10px;
}
.jobTitle {
  color: #f7ecdb;
  font-size: 20px;
  text-shadow: 2px 2px #b8837e;
  font-family: "Cormorant", serif;
  text-align: center;
  margin-top: 9px;
}

@media only screen and (min-width: 800px) {
  .selfie {
    width: 400px;
  }
  .MyNameIs {
    font-size: 40px;
  }
  .sam {
    font-size: 40px;
  }
  .jobTitle {
    font-size: 30px;
  }
}
