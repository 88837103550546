#about {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.aboutMeHeader {
  color: #6e6f58;
  font-family: "Advent Pro", sans-serif;
  text-shadow: 1px 1px #485543;
  font-size: 25px;
  text-align: center;
}
#aboutMeDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
}
.aboutMe {
  color: #f7ecdb;
  font-family: "Advent Pro", sans-serif;
  font-size: 15px;
  width: 300px;
}
.aboutMe2 {
  color: #f7ecdb;
  font-family: "Advent Pro", sans-serif;
  font-size: 15px;
  width: 300px;
}

@media only screen and (min-width: 800px) {
  #aboutMeDiv {
    margin-left: 0px;
    display: flex;
    justify-content: center;
  }
  .aboutMe {
    width: 900px;
    margin: 10px 0px 10px 120px;
    font-size: 25px;
  }
  .aboutMe2 {
    width: 900px;
    margin: 10px 0px 10px 120px;
    font-size: 25px;
  }
  .aboutMeHeader {
    font-size: 45px;
  }
}
